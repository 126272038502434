<template lang="en">
  <div class="overflow-hidden d-flex justify-content-center align-items-center">
    <div class="row px-md-8 px-4">
      <div class="col-md-6 auth-bg login-image">
        <div class="d-flex ms-4 mt-4">
          <img :src="require('@/assets/logo-auth.png')" alt="logo" class="auth-logo me-2" />
          <h1 class="auth-logo-text">Propelond</h1>
        </div>
        <p
          class="login-intro__header d-md-block text-white text-center text-wrap align-middle mt-md-10"
        >
          Hello friend!
        </p>
        <p
          class="login-intro d-md-block text-white text-center text-wrap align-middle"
        >
          Connect with friends and the world around you on Propelond.
        </p>
      </div>
      <div class="col-md-6 bg-white px-md-5 py-md-6 py-2 right-auth-card">
        <h2 class="auth-text mb-3 ms-2">Log In</h2>
        <form @submit.prevent="signIn">
          
              <div class="row mb-4">
                <div class="col">
                  <div class="input-group">
                    <input
                      id="email"
                      type="email"
                      class="form-control form-control-lg border-end-0"
                      name="email"
                      required
                      autocomplete="email"
                      autofocus
                      placeholder="Email"
                      v-model="email"
                    />
                    <label class="input-group-text">
                      <i class="bi bi-envelope-fill"></i>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <div class="input-group">
                    <input
                      id="password"
                      type="password"
                      class="form-control form-control-lg border-end-0"
                      name="password"
                      required
                      autocomplete="current-password"
                      placeholder="Password"
                      v-model="password"
                    />
                    <label class="input-group-text">
                      <i class="bi bi-lock-fill"></i>
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-1 mb-md-4 mb-2">
                  <router-link
                    to="/forgot-password"
                    class="btn-link text-decoration-none text-dark"
                    > Forgot Password? </router-link>
                </div>
              </div>
              <div v-if="status_type === 'error'" class="text-danger">
                {{ message }}
              </div>

              <div class="d-grid gap-2">
                <button
                  type="submit"
                  class="btn btn-auth btn-lg text-white text-decoration-none"
                >
                  Login
                </button>
                <p>
                  By clicking Login, you are agreeing to our
                  <router-link
                    to="/legal/terms-of-service"
                    class="text-decoration-none"
                  >
                    Terms of Service
                  </router-link>
                  and
                  <router-link to="/legal/privacy" class="text-decoration-none">
                    Privacy Policy
                  </router-link>
                </p>

                <hr class="w-75 mx-auto"/>
                <router-link
                  to="/signup"
                  class="btn btn-link text-decoration-none text-dark"
                  >Don’t yet have an account?
                  <span class="text-primary">Register</span></router-link
                >
              </div>
           
       
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),
    signIn() {
      this.login({ email: this.email, password: this.password })
    }
  },
  computed: {
    ...mapState("status", ["status_type", "message"])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/auth";

.form-control {
  height: 54px;
}

.btn-ath {
  height: 50px;
}
.login-intro {
  font-size: 0.8rem;
}
.login-intro__header {
  font-size: 1.5rem;
}

.login-image {
  background-image: url(../assets/img/login-bg.png);
}

@media (max-width: 640px) {
  .login-image {
    background-image: url(../assets/img/login-mobile-bg.png);
  }
}
</style>
